#searchhome {
    height: calc(100vh - 86px);
    min-height: 450px;
    position: relative;
}
.search-wrapper {
    margin-top: 30px;
    margin-bottom: 30vh;
    width: 100%;
}
.search-scroll-more {
    position: absolute;
    bottom: 5px;
}