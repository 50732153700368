  :root {
  --background: white !important;
  --background-profile: #f6f6f6;
  --background-secondary: black;
  --background-tertiary: #f6f6f6;
  --background-navigation: white !important;
  --background-create-stages: #f9fafb;
  --text-primary: black;
  --text-primary-oppo: white;
  --text-secondary: #5C5C5C;
  --text-tertiary: #F5F5F5;
  --theme-main: #58B792;
  --theme-main-light: #429775;
  --theme-valid: #E3FADF;
  /* --shadow: 0px 4px 15px rgba(0, 0, 0, 0.05); */
  --accent: purple;
  --accordion-chevron: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --select-chevron: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  --mode-switch: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
  --mode-switch-label: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill='%235C5C5C' d='M12 11.807C10.7418 10.5483 9.88488 8.94484 9.53762 7.1993C9.19037 5.45375 9.36832 3.64444 10.049 2C8.10826 2.38205 6.3256 3.33431 4.92899 4.735C1.02399 8.64 1.02399 14.972 4.92899 18.877C8.83499 22.783 15.166 22.782 19.072 18.877C20.4723 17.4805 21.4245 15.6983 21.807 13.758C20.1625 14.4385 18.3533 14.6164 16.6077 14.2692C14.8622 13.9219 13.2588 13.0651 12 11.807V11.807Z'%3E%3C/path%3E%3C/svg%3E");
  --nav-toggler: url("data:image/svg+xml,%3Csvg width='30' height='22' viewBox='0 0 30 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28 17.4C29.1046 17.4 30 18.2954 30 19.4C30 20.5046 29.1046 21.4 28 21.4H2C0.895431 21.4 0 20.5046 0 19.4C0 18.2954 0.895431 17.4 2 17.4H28ZM28 8.7C29.1046 8.7 30 9.59543 30 10.7C30 11.8046 29.1046 12.7 28 12.7H2C0.895431 12.7 0 11.8046 0 10.7C0 9.59543 0.895431 8.7 2 8.7H28ZM28 0C29.1046 0 30 0.895431 30 2C30 3.10457 29.1046 4 28 4H2C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0H28Z' fill='%2358B792'/%3E%3C/svg%3E%0A");
  --mp-filter: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 7L11 7' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle r='2' transform='matrix(-1 0 0 1 5 7)' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M3 17L13 17' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='19' cy='17' r='2' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
  --mp-filter-close: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235C5C5C'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  --mp-heart-icon: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.3127 8.56326L8.89474 15.5L15.4768 8.56326C17.227 6.71868 17.227 3.72802 15.4768 1.88344C13.7265 0.0388538 10.8888 0.038855 9.13852 1.88344L8.89474 2.14035L8.65096 1.88344C6.9007 0.038855 4.06296 0.0388544 2.3127 1.88344C0.562434 3.72802 0.562435 6.71868 2.3127 8.56326Z' stroke='%233C3D41'/%3E%3C/svg%3E");
  --aa-icon-color-rgb: 88,183,146 !important;
  --aa-selected-color-rgb: 88,183,146 !important;
  --aa-selected-color-alpha: 0.3 !important;
  --aa-scrollbar-thumb-background-color-rgb: 88,183,146;
  --aa-scrollbar-thumb-background-color-alpha: 0.8;
  --swiper-theme-color: #58B792 !important;
  --footer-link: #475467;
  --footer-link-lower: #667085;
}
[data-theme='dark'], body.dark {
  --background: #30353b !important;
  --background-profile: #212529;
  --background-tertiary: #30353b;
  --background-secondary: white;
  --background-navigation: #212529 !important;
  --background-create-stages: #212529;
  --text-primary: rgba(255,255,255,.87);
  --text-primary-oppo: black;
  /* --text-secondary: rgba(125, 179, 151, 0.733); */
  --text-secondary: rgba(182, 182, 182, 0.904);
  --text-tertiary: rgba(182, 182, 182, 0.904);
  --theme-main: #58B792;
  --theme-main-light: #429775;
  --theme-valid: #57F2AB;
  /*--shadow: 0px 4px 15px rgba(70, 70, 70, 0.74);*/
  --accent: darkred;
  --accordion-chevron: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(255,255,255,.87)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --select-chevron: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  --mode-switch: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='grey'/%3e%3c/svg%3e");
  --mode-switch-label: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.995 12C6.995 14.761 9.241 17.007 12.002 17.007C14.763 17.007 17.009 14.761 17.009 12C17.009 9.239 14.763 6.993 12.002 6.993C9.241 6.993 6.995 9.239 6.995 12ZM11 19H13V22H11V19ZM11 2H13V5H11V2ZM2 11H5V13H2V11ZM19 11H22V13H19V11Z' fill='rgba(182, 182, 182, 0.904)'%3E%3C/path%3E%3Cpath d='M5.63702 19.778L4.22302 18.364L6.34402 16.243L7.75802 17.657L5.63702 19.778Z' fill='rgba(182, 182, 182, 0.904)'%3E%3C/path%3E%3Cpath d='M16.242 6.34405L18.364 4.22205L19.778 5.63605L17.656 7.75805L16.242 6.34405Z' fill='rgba(182, 182, 182, 0.904)'%3E%3C/path%3E%3Cpath d='M6.34402 7.75902L4.22302 5.63702L5.63802 4.22302L7.75802 6.34502L6.34402 7.75902Z' fill='rgba(182, 182, 182, 0.904)'%3E%3C/path%3E%3Cpath d='M19.778 18.3639L18.364 19.7779L16.242 17.6559L17.656 16.2419L19.778 18.3639Z' fill='rgba(182, 182, 182, 0.904)'%3E%3C/path%3E%3C/svg%3E");
  --nav-toggler: url("data:image/svg+xml,%3Csvg width='30' height='22' viewBox='0 0 30 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28 17.4C29.1046 17.4 30 18.2954 30 19.4C30 20.5046 29.1046 21.4 28 21.4H2C0.895431 21.4 0 20.5046 0 19.4C0 18.2954 0.895431 17.4 2 17.4H28ZM28 8.7C29.1046 8.7 30 9.59543 30 10.7C30 11.8046 29.1046 12.7 28 12.7H2C0.895431 12.7 0 11.8046 0 10.7C0 9.59543 0.895431 8.7 2 8.7H28ZM28 0C29.1046 0 30 0.895431 30 2C30 3.10457 29.1046 4 28 4H2C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0H28Z' fill='%2358B792'/%3E%3C/svg%3E%0A");
  --aa-text-color-rgb: 255,255,255 !important;
  --mp-filter: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 7L11 7' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle r='2' transform='matrix(-1 0 0 1 5 7)' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M3 17L13 17' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='19' cy='17' r='2' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
  --mp-filter-close: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23B6B6B6'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  --mp-heart-icon: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.3127 8.56326L8.89474 15.5L15.4768 8.56326C17.227 6.71868 17.227 3.72802 15.4768 1.88344C13.7265 0.0388538 10.8888 0.038855 9.13852 1.88344L8.89474 2.14035L8.65096 1.88344C6.9007 0.038855 4.06296 0.0388544 2.3127 1.88344C0.562434 3.72802 0.562435 6.71868 2.3127 8.56326Z' stroke='%233C3D41'/%3E%3C/svg%3E");
  --aa-background-color-rgb: 33,37,41;
  --aa-scrollbar-track-background-color-rgb: 48,53,59;
  --aa-icon-color-rgb: 88,183,146 !important;
  --aa-selected-color-rgb: 88,183,146 !important;
  --aa-selected-color-alpha: 0.65 !important;
  --aa-scrollbar-thumb-background-color-rgb: 88,183,146;
  --aa-scrollbar-thumb-background-color-alpha: 0.8;
  --swiper-theme-color: #58B792 !important;
  --footer-link: rgba(182, 182, 182, 0.904);
  --footer-link-lower: rgba(182, 182, 182, 0.904);
}
.main-container {
  min-height: 100vh; 
  display: block;
  position: relative;
  /* padding-bottom: 100px;  */
  color: var(--text-primary);
  background-color: var(--background);
  max-width: 100vw !important;
  overflow: visible;

 } 


.bg-light {
  background-color: var(--background) !important;
}

/* Panel section autocomplete */
.aa-PanelSections {
  column-gap: var(--aa-spacing);
  display: flex;
}

.aa-PanelSection {
  display: flex;
  flex-direction: column;
}

.main-nav > .aa-Panel {
  z-index: 10000 !important;
  position: fixed !important; 
  top: 60px !important;
}

/* Products autocomplete */
.aa-Source[data-autocomplete-source-id='market'] .aa-List {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media only screen and (max-width: 1200px) {
  .aa-Source[data-autocomplete-source-id='market'] .aa-List {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 991px) {
  .aa-Source[data-autocomplete-source-id='market'] .aa-List {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 500px) {
  .aa-Source[data-autocomplete-source-id='market'] .aa-List {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width:768px) {
  .row {
    --bs-gutter-x: 0rem !important;
  }
}

.aa-ItemActions .register {
  background-color: white;
  border-radius: 34px;
  border: 1px solid black;
  height: 42px;
  padding: 6px 30px;
  color: black;
}

.aa-ItemActions .register:hover {
  background-color: black;
  border-radius: 34px;
  border: 1px solid black;
  height: 42px;
  padding: 6px 30px;
  color: white;
}

.nav .aa-DetachedSearchButton {
  width: 22px;
  border: none !important;
  background: none !important;
}

.nav .aa-DetachedSearchButtonIcon {
  color: #58B792 !important;
  cursor: pointer;
}

.hits-row{
    --bs-gutter-x: 0 !important
}

.mui-img{
  background-color: var(--background-profile) !important;
  max-width: 100%;
}

.MuiCircularProgress-colorPrimary {
  color: var(--theme-main) !important ;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.mp-row{
  overflow-y: visible !important; 
}

.sidebar-col{
  transition: all ease 1s;
}


.stop-scrolling {
  height: 100%;
  overflow: hidden;
}