@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jolly+Lodger&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Mistral";
  src: local("Mistral"), url("./fonts/MISTRAL.TTF") format("truetype");
}

@font-face {
  font-family: "Chalkboard";
  src: local("Chalkboard"), url("./fonts/Chalkboard.otf") format("opentype");
}


html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Jolly Lodger", "Baloo Paaji 2", "Mistral", "Mitr", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: var(--background);
  color: var(--text-primary);
  overflow-x: hidden;
}

code {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
}
h1 {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";

  font-weight: 900;
  font-size: calc(1.75rem + 1.5vw);
  color: #58b792;
}
h2 {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";

  font-weight: 400;
  font-size: calc(1.3rem + 1.2vw);
}
h3 {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";

  font-weight: 700;
  font-size: calc(1rem + 0.6vw);
}
h4 {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";

  font-weight: 700;
  font-size: calc(0.9rem + 0.4vw);
}
.content-container {
  width: 92% !important;
  min-height: 100vh !important;
  max-width: none !important;
  padding-bottom: 4rem;
}

.content-container-v3 {
  display: flex;
  justify-content: center;
  background-color: var(--background-profile);
  width: 100% !important;
  min-height: 100vh !important;
  max-width: none !important;
  padding-bottom: 4rem;
}

.footer-container {
  width: 92% !important;
  max-width: none !important;
}

@media only screen and (max-width: 1200px) {
  .content-container {
    width: 97% !important;
    padding-bottom: 3rem;
  }
  .footer-container {
    width: 97% !important;
  }
}
p {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  color: var(--text-primary);
}
a {
  color: var(--text-primary);
}
a:hover {
  color: var(--text-primary);
}
.toastNotification {
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-size: 12px;
  color: var(--text-primary) !important;
  word-wrap: break-word;
  text-align: center;
  background-color: var(--background) !important;
}
.toastNotification a {
  color: var(--text-primary);
}
.seperator50 {
  height: 1px;
  width: 50%;
  background-color: #d7d7d7;
}
.seperator {
  height: 1px;
  width: 80%;
  background-color: #d7d7d7;
}
.seperator100 {
  height: 1px;
  width: 100%;
  background-color: #d7d7d7;
}
.copyicon {
  cursor: pointer;
}

.main-container {
  background-color: var(--background-profile);
  min-height: calc(100vh - 218px);
}

.form-inputs input,
select {
  background-color: var(--background) !important;
  border: none !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  height: 40px;
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary) !important;
  padding-left: 15px !important;
}
.form-inputs input:focus,
select:focus {
  background-color: var(--background);
  border: none !important;
  box-sizing: border-box;
  border-radius: 40px;
  height: 40px;
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  padding-left: 15px !important;
  box-shadow: none !important;
}
.form-inputs textarea {
  background-color: var(--background) !important;
  border: none !important;
  box-sizing: border-box;
  box-shadow: none !important;
  border-radius: 20px !important;
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary) !important;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.form-inputs textarea:focus {
  background-color: var(--background) !important;
  border: none !important;
  box-sizing: border-box;
  box-shadow: none !important;
  border-radius: 20px !important;
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary) !important;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.form-select {
  background-image: var(--select-chevron);
  border: none !important;
  font-size: small !important;
}

.form-select:focus {
  background-image: var(--select-chevron);
  border: none !important;
  font-size: small !important;
}

.form-control {
  background-color: var(--background) !important;
  color: var(--text-primary) !important;
}
.form-control:focus {
  background-color: var(--background) !important;
  color: var(--text-primary) !important;
}

.aa-Autocomplete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aa-Form {
  background-color: var(--background) !important;
  border: 1px solid #ced4da !important;
  border-radius: 40px !important;
  color: var(--text-primary) !important;
  width: 70%;
  max-width: 777px;
}

#searchhome .aa-Form:focus-within {
  border: 1px solid #58b792 !important;
}

#searchhome .aa-Form:focus-within svg {
  color: #58b792 !important;
}

#navbar .aa-Form {
  height: 40px;
  border: 1px solid #ced4da !important;
  width: 100%;
}

#navbar .aa-Form:focus-within {
  border: 1px solid #58b792 !important;
}

#navbar .aa-Form:focus-within svg {
  color: #58b792 !important;
}

.aa-Form:focus-within {
  border: none;
  box-shadow: none;
  color: var(--text-primary) !important;
}

.aa-Input::placeholder {
  color: #8e8e8e !important;
}

.aa-Input {
  color: var(--text-primary) !important;
}

.aa-DetachedSearchButton {
  background-color: var(--background) !important;
  border-radius: 40px !important;
  color: #8e8e8e !important;
  width: 85%;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  height: 52px !important;
}

.aa-DetachedSearchButton :focus {
  background-color: var(--background) !important;
  border-radius: 40px !important;
  color: #8e8e8e !important;
  width: 85%;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  height: 52px !important;
}

.aa-DetachedSearchButtonIcon {
  color: var(--text-secondary) !important;
}

.aa-Label svg {
  color: var(--text-secondary) !important;
}

/* .aa-Label svg:hover{
  color: #58B792 !important;
} */

#searchhome .aa-SubmitButton {
}

#searchhome input {
  background-color: var(--background) !important;
  border: none !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  height: 52px !important;
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary) !important;
  padding-left: 15px;
}

#searchhome input:focus {
  background: var(--background);
  border: none !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  height: 52px !important;
  font-family: "Inter", system-ui, -apple-system, "Segoe UI", "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  padding-left: 15px;
  box-shadow: none !important;
}

.commerceinfo {
  border-radius: 10px;
  background-color: var(--background-profile);
}
.card {
  background: var(--background);
  box-shadow: var(--shadow);
  border-radius: 10px;
  height: 100%;
  border: none !important;
}
.outer-wrapper {
  position: relative;
  height: 100%;
}
.valid-for {
  height: 22px;
  width: auto;
  background: var(--theme-valid);
  border-radius: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  /* identical to box height, or 160% */
  letter-spacing: 0.2px;
}

.new-bids {
  height: 22px;
  width: auto;
  background: var(--background-profile);
  border-radius: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  /* identical to box height, or 160% */
  letter-spacing: 0.2px;
}

.valid-for img {
  margin-right: 5px;
}

.themeColor {
  color: #58b792;
}

.secColor {
  color: var(--text-secondary);
}

.smallTextSpan {
  font-size: small !important;
}

.imageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon {
  color: var(--text-secondary);
}

.linkIcon:hover {
  color: var(--theme-main);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: var(--text-primary);
  background-color: var(--background-profile);
  -webkit-text-fill-color: var(--text-primary);
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

::selection {
  background: var(--theme-main) !important; /* WebKit/Blink Browsers */
  color: var(--text-primary);
}
::-moz-selection {
  background: var(--theme-main) !important; /* Gecko Browsers */
  color: var(--text-primary);
}

.modal-backdrop.show {
  /* box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5); */
  filter: blur(3px);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  opacity: 0.88;
}
.modal-dialog {
  min-width: 32vw !important  ;
}

@media only screen and (max-width: 992px) {
  .dropdown-menu-end[data-bs-popper] {
    right: auto;
    left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .modal {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .modal-content {
    min-height: 88vh;
    top: 12vh;
    width: 100%;
    margin-left: 0 !important;
    bottom: 0 !important;
    border-radius: 24px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .modal-dialog {
    margin: 0 !important;
    bottom: 0 !important;
  }

  .modal-dialog-centered {
    display: flex;
    align-items: flex-end !important;
    min-height: 100%;
  }
}


.truncate {
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.imageAutoHeight {
  max-height: 25px;
}