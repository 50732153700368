@media screen and (min-width: 1600px) {
  .trait-root{
    .singles-grid{
      grid-template-columns: repeat(5, 1fr);
    }

    .level-2{
      .singles-grid{
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .trait-root{
    .singles-grid{
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 1280px) {
  .trait-root{
    .singles-grid{
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 1024px) {
  .trait-root{
    .singles-grid{
      grid-template-columns: 100%;
    }

    .level-2{
      padding-left: 0;
      .singles-grid{
        grid-template-columns: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .trait-root{
    .singles-grid{
      grid-template-columns: repeat(3,1fr);
    }
  }
}

@media screen and (max-width: 480px) {
  .trait-root{
    .singles-grid{
      grid-template-columns: 100%;
    }

    .level-2{
      padding-left: 0;
      .singles-grid{
        grid-template-columns: 100%;
      }
    }
  }


}