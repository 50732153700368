#find-pack-modal-wrapper {
  #pack-img {
    height: 82%;
    border-radius: 8px;
  }

  #pack-img-loading {
    height: 41%;
    border-radius: 50%;
    position: absolute;
    animation: bounce 1s linear infinite;

  }

  #pack-open-title {
    font-family: "Mistral";
    font-size: 4rem;
    color: #ff00a6;
    margin-bottom: 0;
  }

  #pack-open-id {
    font-family: "Baloo Paaji 2";
    font-size: 1.2rem;
    color: var(--text-primary);
  }

  a{
    text-decoration: none;
  }

  #item-info-col{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}