.swiper {
  /* width: 400px;
  height: 400px; */
  max-width: 460px;
  min-height: 500px;
}

#colSwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--background) !important;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center !important; /* add this will all relevant prefixes */
  border-radius: 8px;
  cursor:grab
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.swiper-slide video {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;

  }

.swiper-wrapper{
    align-items: center !important
}

.swiper-pagination-bullet {

}
.swiper-pagination-bullet-active {
}
.swiper-pagination-bullet-active-main {

}

.swiper-pagination-bullet-active-next{

}

.swiper-pagination-bullet-active-next-next{

}

.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
    opacity: 0.3;
    transition: all ease 0.4s;
  }

  .swiper-button-next:hover  {
    opacity: 1;
    transform: translateX(5px);
  }

  .swiper-button-prev:hover{
    opacity: 1;
    transform: translateX(-5px);
  }

  

@media only screen and (max-width: 576px) {
  .swiper {
    width: 100%;
}}