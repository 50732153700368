
#breadcrumbs-container {
    color: var(--text-secondary);
    }

    .bc-link{
        color: var(--text-secondary);
        text-decoration: none;

    }

    .bc-link:hover{
        text-decoration: underline;
        cursor: pointer;
        }