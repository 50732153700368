.btn-find {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary-oppo);
  height: 52px;
  padding: 10px 40px;
  background: var(--theme-main);
  border-radius: 34px;
  border-color: var(--theme-main);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-find:hover {
  color: var(--text-primary-oppo);
  height: 52px;
  padding: 10px 40px;
  background: #58b792;
  /* shadow */
  border-radius: 34px;
  border-color: #58b792;
}
.btn-find:focus {
  height: 52px;
  padding: 10px 40px;
  background: #58b792;
  /* shadow */
  border-radius: 34px;
  border-color: #58b792;
}
.btn-find:active {
  height: 52px;
  padding: 10px 40px;
  background: #58b792;
  /* shadow */
  border-radius: 34px;
  border-color: #58b792;
}
.btn-find:active:focus {
  height: 52px;
  padding: 10px 40px;
  background: #58b792;
  /* shadow */
  border-radius: 34px;
  border-color: #58b792;
}
.btn-find-skinny {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary-oppo);
  height: 40px;
  padding: 0px 40px;
  background: var(--theme-main);
  border-radius: 34px;
  border-color: var(--theme-main);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-find-outline-dark {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-find-outline-dark:hover {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  padding: 10px 40px;
}
.btn-find-outline-dark:focus {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  padding: 10px 40px;
  box-shadow: none;
}
.btn-find-outline-commerce {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  height: 32px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  padding: 0px 30px;
}
.btn-find-outline-commerce:hover {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  padding: 0px 30px;
}
.btn-find-outline-commerce:focus {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  padding: 0px 30px;
  box-shadow: none;
}
.btn-find-outline-commerce-dark,
.btn-find-outline-commerce-dark:hover,
.btn-find-outline-commerce-dark:focus,
.btn-find-outline-commerce-dark:focus-within {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  height: 40px;
  background-color: black;
  border-color: black;
  color: white;
  padding: 0px 30px;
    box-shadow: none;

}


.btn-find-outline-commerce-light,
.btn-find-outline-commerce-light:hover,
.btn-find-outline-commerce-light:focus,
.btn-find-outline-commerce-light:focus-within {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  height: 40px;
  background-color: var(--background);
  border-color: #d0d5dd;
  color: var(--text-primary);
  padding: 0px 30px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-find-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  height: 52px;
  padding: 10px 40px;
}
.btn-find-dark:hover {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  height: 52px;
  padding: 10px 40px;
}
.btn-find-dark:focus {
  border-radius: 34px;
  border-color: var(--text-primary);
  color: var(--text-primary);
  height: 52px;
  padding: 10px 40px;
  box-shadow: none;
}
.btn-find-nochange-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  background-color: black;
  border-color: black;
  color: white;
  height: 52px;
  padding: 10px 40px;
}
.btn-find-nochange-dark:hover {
  border-radius: 34px;
  background-color: black;
  border-color: black;
  color: white;
  height: 52px;
  padding: 10px 40px;
}
.btn-find-nochange-dark:focus {
  border-radius: 34px;
  background-color: black;
  border-color: black;
  color: white;
  height: 52px;
  padding: 10px 40px;
}
.btn-find-outline-commerce-red {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  height: 32px;
  border-color: rgb(255, 42, 42);
  color: rgb(255, 42, 42);
  padding: 0px 30px;
}
.btn-find-outline-commerce-red:hover {
  border-radius: 34px;
  border-color: rgb(255, 42, 42);
  color: rgb(255, 42, 42);
  padding: 0px 30px;
}
.btn-find-outline-commerce-red:focus {
  border-radius: 34px;
  border-color: rgb(255, 42, 42);
  color: rgb(255, 42, 42);
  padding: 0px 30px;
}
.btn-find-text,
.btn-find-text:hover,
.btn-find-text:focus,
.btn-find-text:active {
  color: var(--text-primary);
  background-color: transparent;
  border: none;
  box-shadow: none;
  text-decoration: underline;
}

.btn-find-clear {
  color: var(--text-primary);
  font-size: 12px;
  border-radius: 10px;
  border: 2px solid var(--text-secondary);
  background-color: var(--background);
  padding-top: 2px;
  padding-bottom: 2px;
}

.btn-find-collection-outline,
.btn-find-collection-outline:hover,
.btn-find-collection-outline:focus {
  color: var(--text-primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 58px;
  box-shadow: none !important;
}

.btn-find-collection-outline-red,
btn-find-collection-outline-red:hover,
btn-find-collection-outline-red:focus {
  color: rgb(255, 42, 42) !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid rgb(255, 42, 42);
  border-radius: 58px;
}

.btn-find-collection-outline-dark,
.btn-find-collection-outline-dark:hover,
.btn-find-collection-outline-dark:focus {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 58px;
  background-color: black;
}

.btn-find-collection-pfp-outline,
.btn-find-collection-pfp-outline:hover,
.btn-find-collection-pfp-outline:focus {
  color: var(--text-primary);
  background-color: var(--text-tertiary);

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  height: 44px;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important
}

.btn-find-collection-burn-outline,
.btn-find-collection-burn-outline:hover,
.btn-find-collection-burn-outline:focus {
  color: var(--text-primary);
  background-color: #e25822;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  height: 44px;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important

}

.btn-find-collection-pack-outline,
.btn-find-collection-pack-outline:hover,
.btn-find-collection-pack-outline:focus {
  color: var(--text-primary);
  background-color: var(--theme-main);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  height: 44px;
  border-radius: 8px;
}

.btn-find-claim-pack-yellow,
.btn-find-claim-pack-yellow:hover,
.btn-find-claim-pack-yellow:focus {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffdb1e;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 68px;
  padding: 0.5rem;
}
.btn-find-thoughts-post,
.btn-find-thoughts-post:hover,
.btn-find-thoughts-post:focus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 96px;
  height: 40px;
  background: #000000;
  border: 1px solid #000000;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 58px;
  color: #ffffff;
  font-weight: 600;
  margin-left: 0.5rem;
}

.carousel-button-right {
  position: absolute;
  top: 30%;
  right: -50px;
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--background) !important;

  color: var(--text-primary);
  border: 1px solid var(--text-secondary);
}

.carousel-button-right:focus {
  color: var(--text-primary) !important;
}

.carousel-button-right:hover {
  color: var(--text-primary) !important;
}

.page-button-right {
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--background) !important;

  color: var(--text-primary) !important;
  border: 1px solid var(--text-secondary);
}

.page-button-right:focus {
  color: var(--text-primary) !important;
}

.page-button-right:hover {
  color: var(--text-primary) !important;
}

.carousel-button-left {
  position: absolute;
  top: 30%;
  left: -50px;
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--background) !important;
  color: var(--text-primary);
  border: 1px solid var(--text-secondary);
}

.carousel-button-left:focus {
  color: var(--text-primary) !important;
}

.carousel-button-left:hover {
  color: var(--text-primary) !important;
}

.page-button-left {
  margin: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--background) !important;
  color: var(--text-primary) !important;
  border: 1px solid var(--text-secondary);
}

.page-button-left:focus {
  color: var(--text-primary) !important;
}

.page-button-left:hover {
  color: var(--text-primary) !important;
}

@media only screen and (max-width: 576px) {
  .carousel-button-left {
    display: none;

    position: static;
  }

  .carousel-button-right {
    display: none;
    position: static;
  }
}

.carousel-button-left-explore {
  border-radius: 50% !important;
  width: 55px;
  height: 55px;
  background-color: var(--background-tertiary) !important;
  border: 1px solid var(--background-tertiary) !important;
  color: var(--text-primary) !important;
  margin-right: 2rem !important;
}

.carousel-button-left-explore:focus {
  color: var(--text-primary) !important;
  box-shadow: none !important;
}

.carousel-button-left-explore:hover {
  color: var(--theme-main) !important;
  border: 1px solid var(--theme-main) !important;
}

.carousel-button-right-explore {
  border-radius: 50% !important;
  width: 55px;
  height: 55px;
  background-color: var(--background-tertiary) !important;
  border: 1px solid var(--background-tertiary) !important;
  color: var(--text-primary) !important;
  margin-left: 2rem !important;
}

.carousel-button-right-explore:focus {
  color: var(--text-primary) !important;
  box-shadow: none !important;
}

.carousel-button-right-explore:hover {
  color: var(--theme-main) !important;
  border: 1px solid var(--theme-main) !important;
}