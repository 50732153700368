#drop-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-profile);

  a {
    text-decoration: none;
  }

  #drop-page-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
    #new-drop-span-wrapper {
      display: flex;
      align-items: center;
      background-color: var(--theme-main-light);
      padding: 0.2rem;
      border-radius: 25px;
      font-size: 0.8rem;
      opacity: 1;
      #new-drop-span {
        background-color: var(--background);
        border-radius: 25px;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
      }
    }
    #header-title-span {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }

  #drop-page-banner-bio-wrapper {
    width: 64%;
    margin-top: 2rem;
    margin-bottom: 2rem !important;
    .profile-pic {
      background-color: var(--background);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      position: relative;

      img {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        border: 1px solid #dedede;
        // box-sizing: border-box;
        padding: 0rem;
      }
    }
    #links-col {
      display: flex;
      margin-left: auto;
      margin-bottom: 8px;
      .link-item {
        border: 1px solid grey;
        border-radius: 8px;
        padding: 0.4rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-secondary);
        a {
          text-decoration: none;
        }
      }
      .link-item:hover {
        border: 1px solid var(--theme-main);

        i {
          color: var(--theme-main);
        }
      }
    }
  }

  #drop-page-mint-box-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: #0c0303;
    background-image: url("./dre-bg.png");
    background-position: bottom;
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;

    #drop-page-mint-box-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 64%;
      #drop-page-mint-box-image-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;
        img {
          border-radius: 10px;
          max-width: 100%;
        }
      }
      #drop-page-mint-box-title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #401333;
        border: 3px solid #000000;
        border-radius: 24px;
        min-height: 30vh;
        margin-bottom: 2rem;
        mix-blend-mode: normal;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          inset -17px 17px 23px rgba(0, 0, 0, 0.25);
        #mint-box-title-span {
          color: #ff00a6;
          font-family: "Mistral";
          font-size: 5rem;
          text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
        }
        #mint-box-subtitle-span {
          color: #ffffff;
          font-family: "Mitr";
          font-size: 1.5rem;
          font-weight: 600;
          // text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
        }
      }
      #drop-page-mint-box-mint-wrapper {
        scroll-padding: 96px !important;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #401333;
        border: 3px solid #000000;
        border-radius: 24px;
        min-height: 30vh;
        margin-bottom: 2rem;
        mix-blend-mode: normal;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          inset -17px 17px 23px rgba(0, 0, 0, 0.25);
        #drop-page-mint-box-mint-purchase-span {
          color: #ffffff;
          font-family: "Mitr";
          font-size: 4rem;
        }
        #drop-page-mint-box-mint-price-span {
          color: #ffdb1e;
          font-family: "Mitr";
          font-weight: 500;
          font-size: 2rem;
        }

        #packs-available-span {
          color: #ffffff;
          font-family: "Mitr";
          font-size: 1.5rem;
          font-weight: 600;
        }

        #mint-box-button-row {
          width: 40%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-around;

          // select{
          //   width:500px;
          // }
          #mint-box-button-row-col {
            width: 30%;
            margin-top: 1rem;
            margin-bottom: 1rem;

            select {
              width: 100%;
              border-radius: 8px !important;
            }
            button {
              width: 100%;
            }
          }
        }
      }

      #drop-page-mint-box-splash-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        #drop-page-mint-box-splash-title-span {
          color: #ff00a6;
          font-family: "Mistral";
          font-size: 5rem;
          text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
        }
        #drop-page-mint-box-splash-subtitle-span {
          color: #ffdb1e;
          font-family: "Mitr";
          font-weight: 500;
          font-size: 2.5rem;
        }
        #drop-page-mint-box-splash-desc-span {
          color: #ffffff;
          font-family: "Mitr";
          font-size: 1.5rem;
          font-weight: 600;
          // text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  #drop-page-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    width: 100%;

    #drop-page-info-container {
      width: 64%;
      #drop-page-info-section-wrapper {
        display: flex;
        width: 100%;
        background-color: #000000;
        margin-top: 4rem;
        margin-bottom: 4rem;

        .textLeft {
          flex-direction: row;
        }
        .textRight {
          flex-direction: row-reverse;
        }
        #drop-page-info-section-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;

          #drop-page-info-section-text {
            padding: 2rem;
            width: 50%;
            display: flex;
            flex-direction: column;
            #drop-page-info-section-title-span {
              color: #ff00a6;
              font-family: "Mistral";
              font-size: 3rem;
            }
            #drop-page-info-section-desc-span {
              color: #ffffff;
              font-family: "Mitr";
              font-size: 1.5rem;
              font-weight: 600;
              display: inline-block;
            }
          }
          #drop-page-info-section-img {
            width: 50%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  #drop-page-last-drop-wrapper {
    display: flex;
    width: 100%;
    background-color: #000000;
    margin-top: 4rem;
    margin-bottom: 4rem;

    #drop-page-last-drop-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      #drop-page-last-drop-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 2rem;

        #drop-page-last-drop-title-span {
          color: #f60000;
          font-family: "Jolly Lodger";
          font-size: 3rem;
        }
        #drop-page-last-drop-desc-span {
          color: #ffffff;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
      #drop-page-last-drop-img {
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  #drop-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-profile);

    #drop-page-header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      #new-drop-span-wrapper {
        display: flex;
        align-items: center;

        background-color: var(--theme-main-light);
        padding: 0.2rem;
        border-radius: 25px;
        font-size: 0.8rem;
        opacity: 1;
        #new-drop-span {
          background-color: var(--background);
          border-radius: 25px;
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;
          padding-left: 0.6rem;
          padding-right: 0.6rem;
        }
      }
      #header-title-span {
        font-size: 3rem;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }

    #drop-page-banner-bio-wrapper {
      width: 92%;
      margin-top: 2rem;
      margin-bottom: 2rem;
      #links-col {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .link-item {
          border: 1px solid grey;
          border-radius: 8px;
          padding: 0.4rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--text-secondary);
          a {
            text-decoration: none;
          }
        }
      }
    }

    #drop-page-mint-box-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      background-color: #0c0303;
      background-image: url("./dre-bg.png");
      background-position: bottom;
      background-size: cover;
      min-height: 100vh;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 4rem;
      padding-bottom: 4rem;

      #drop-page-mint-box-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 92%;
        #drop-page-mint-box-image-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-bottom: 2rem;
        }
        #drop-page-mint-box-title-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #401333;
          border: 3px solid #000000;
          border-radius: 24px;
          min-height: 30vh;
          margin-bottom: 2rem;
          padding: 2rem;
          mix-blend-mode: normal;
          border: 3px solid #000000;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            inset -17px 17px 23px rgba(0, 0, 0, 0.25);

          #mint-box-title-span {
            color: #ff00a6;
            font-family: "Mistral";
            font-size: 5rem;
            text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
          }
          #mint-box-subtitle-span {
            color: #ffffff;
            font-family: "Mitr";
            font-size: 1.5rem;
            font-weight: 600;
            // text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
          }
        }
        #drop-page-mint-box-mint-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #401333;
          border: 3px solid #000000;
          border-radius: 24px;
          min-height: 30vh;
          margin-bottom: 2rem;
          padding: 2rem;
          scroll-padding: 96px !important;

          #drop-page-mint-box-mint-purchase-span {
          
          }
          #drop-page-mint-box-mint-price-span {
            color: #ffdb1e;
            font-family: "Mitr";
            font-weight: 500;
            font-size: 2rem;
          }
          #mint-box-button-row {
            display: flex;
            align-items: center;
            flex-direction: column;

            // select{
            //   width:500px;
            // }
            #mint-box-button-row-col {
              width: 100%;

              select {
                width: 100%;
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                border-radius: 8px !important;
              }
              button {
                width: 100%;
              }
            }
          }
        }

        #drop-page-mint-box-splash-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          #drop-page-mint-box-splash-title-span {
            color: #ff00a6;
            font-family: "Mistral";
            font-size: 5rem;
            text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
          }
          #drop-page-mint-box-splash-subtitle-span {
            color: #ffdb1e;
            font-family: "Mitr";
            font-weight: 500;
            font-size: 2.5rem;
          }
          #drop-page-mint-box-splash-desc-span {
            color: #ffffff;
            font-family: "Mitr";
            font-size: 1.5rem;
            font-weight: 600;
            // text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }

    #drop-page-info-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #000000;
      width: 100%;
      #drop-page-info-container {
        width: 92%;
        #drop-page-info-section-wrapper {
          .textLeft {
            flex-direction: column-reverse;
          }
          .textRight {
            flex-direction: column-reverse;
          }
          #drop-page-info-section-row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            #drop-page-info-section-text {
              padding: 2rem;
              width: 100%;
              display: flex;
              flex-direction: column;
              #drop-page-info-section-title-span {
              }
              #drop-page-info-section-desc-span {
                color: #ffffff;
                font-family: "Mitr";
                font-size: 1.5rem;
                font-weight: 600;
              }
            }
            #drop-page-info-section-img {
              width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    #drop-page-last-drop-wrapper {
      #drop-page-last-drop-row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: space-around;
        #drop-page-last-drop-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 2rem;

          #drop-page-last-drop-title-span {
          }
          #drop-page-last-drop-desc-span {
          }
        }
        #drop-page-last-drop-img {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
