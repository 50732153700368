.navbar-custom {
  background-color: var(--background);
  /* padding-top: 45px; */
  height: 86px !important;
  border-bottom: transparent solid 1px;
  /* z-index: 11111; */
  /* transition: all ease 0.1s; */
}

.navbar-custom-scrolled {
  border-bottom: #d3d3d3 solid 1px !important; 
  /* transition: all ease 0.1s; */
}

.nav-icons {
  color: var(--text-primary);
}

.navbar-collapse {
  position: absolute !important;
  top: 86px;
  left: 0px;
  width: 100%;
  background-color: var(--background-navigation);
}

#lgmenu .dropdown-menu-end {
  margin-right: 20px !important;
  margin-top: 5px !important;
  z-index: 1000000000;
}
#lgmenu .dropdown-menu {
  background-color: var(--background);
  color: var(--text-primary);
  cursor: default;
  filter: drop-shadow(0px 1px 4px rgba(60, 61, 65, 0.2));
  border: 1px solid #d8d8d9;
  border-radius: 8px;
}
#navbar .dropdown-item {
  padding: 8px !important;
  color: var(--text-primary);
}
#navbar .dropdown-item a {
  color: var(--text-primary);
  padding: 12px 12px !important;
}
#navbar .dropdown-item:hover {
  color: var(--text-primary-oppo) !important;
  background-color: var(--background-secondary) !important;
  background-image: none !important;
}
#lgmenu .dropdown-item a:hover {
  color: var(--text-primary-oppo) !important;
  background-color: var(--background-secondary);
  background-image: none !important;
}
#lgmenu .dropdown-item.active {
  background-color: var(--background-secondary) !important;
  color: var(--text-primary-oppo) !important;
}
#lgmenu .dropdown-item.active a {
  background-color: var(--background-secondary) !important;
  color: var(--text-primary-oppo) !important;
}
#lgmenu .dropdown-item:focus {
  background-color: var(--background-secondary) !important;
  color: var(--text-primary-oppo) !important;
}
#lgmenu #dropdown-menu-align-end.btn-dark {
  padding: 0px;
  background-color: transparent !important;
  height: 70px;
  width: 90px;
  border: none;
  box-shadow: none;
}
#lgmenu #dropdown-menu-align-end.btn-dark::after {
  color: var(--text-primary) !important;
}
#lgmenu #dropdown-menu-align-end.btn-dark img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  /* box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15); */
  transition: 0.5s;
}
#dropdown-menu-align-end.btn.btn-primary {
  padding: 0px;
  background-color: transparent !important;
  height: 70px;
  width: 90px;
  border: none;
  box-shadow: none;
  color: var(--text-primary);
}
#dropdown-menu-align-end.btn-primary::after {
  margin-left: 10px;
  color: var(--text-primary) !important;
  display: inline-block;
  /* margin-left: .255em; */
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.profileMenuPic img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  /* box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15); */
  transition: 0.5s;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
}
.profileMenuPic {
  background-color: transparent;
}
.v-seperator {
  border-left: 1px solid #d3d3d3;
  height: 48px;
}
.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}
/*for animation*/
.dropdown-toggle:after {
  transition: 0.2s;
}
#navbar .form-check-input:checked {
  background-color: var(--background);
  border-color: var(--text-primary);
}
#navbar .form-check-input {
  background-color: var(--background);
  border-color: var(--background-secondary);
}
#navbar .form-switch .form-check-input {
  background-image: var(--mode-switch);
}
#navbar .form-switch .form-check-input:checked {
  background-image: var(--mode-switch);
}
#navbar .form-switch .form-check-input:focus {
  box-shadow: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: var(--nav-toggler) !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: var(--nav-toggler) !important;
}

#mode-switch-label {
  width: 24px;
  height: 24px;
  background-image: var(--mode-switch-label);
  margin-right: 8px;
  cursor: pointer;
}

.navbar-toggler {
  border: none !important;
}

.dropdown-container {
  width: 97%;
  position: relative;
  height: calc(100vh - 86px);
}

.dropdown-container .mobile-link {
  font-size: 1.31rem !important;
  font-weight: 450;
}

.dropdown-container .dropdown-auth {
  width: 100%;
  position: absolute;
  bottom: 86px;
}

@media only screen and (max-width: 288px) {
  #navbar .profileMenuPic {
    display: none !important;
  }
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--theme-main) !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--theme-main) !important;
}

#lAndFContainer {
  display: flex;
  position: relative;
}

#lAndFContainer #lAndFNotify {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--theme-main);
  font-size: 0.64rem;
  font-weight: 600;
  width: 0.80rem;
  height: 0.80rem;
  border-radius: 50%;
  right: -0.6rem;
  top: -0.15rem;
  color: white;
}

#lAndFContainer a {
  text-decoration: none;
}

#nav-link-img{
  display: flex;
  align-items: center;
}

#nav-link-img img{
  height: 1.5rem;
  border-radius: 50%;
}