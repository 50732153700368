.footer {
  /* height: 150px; */
}
.footerlogo {
  height: 35px !important;
  display: block;
}
.footersocials a {
  font-size: 20px;
  color: var(--text-primary);
}

.footer-lower {
}

.footersocials a:hover {
  color: #58b792;
}

.copyright {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-secondary);
}

.footer-wrapper {
  position: absolute;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: 363px;
  margin-left: auto;
  margin-right: auto;
  z-index: 112 !important;
  background: var(--background) !important;
}

#footer-lilico-img {
  border-radius: 50% !important;
}

.footer-link-lower {
  text-decoration: none;
  color: var(--footer-link-lower);
  font-size: 0.95rem;
  font-weight: 400;
}

.footer-text-lower {
  text-decoration: none;
  color: var(--footer-link-lower);
  font-size: 0.95rem;
  font-weight: 400;

}

.footer-link-lower:hover {
  color: var(--theme-main);
}

@media only screen and (max-width: 768px) {
  .footer-wrapper {
    height: auto !important;
  }
}
