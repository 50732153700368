.trait-root{
  font-family: sans-serif;
  --theme-main: #58b792;
  --card-border: #58b792;
  --card-bg: rgb(88 183 146 / 15%);
  --text-secondary: #5c5c5c;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  color: #333;

  div{
    box-sizing: border-box;
  }

  h2{
    margin: 0;
    margin-bottom: 0.5em;
  }

  .level-0{
    display: grid;
    grid-gap: 32px;
    margin: 0 auto;
    width: 100%;
  }

  .singles-grid{
    display: grid;
    grid-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
  }

  .card{
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    border-radius: 10px;
    display: grid;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap:10px;

    background-color: var(--card-bg);
    border: 1px solid var(--card-border);

    header{
      text-align: center;
      color: var(--card-border);
    }
    p{
      margin: 0 auto;
      text-align: center;

      &:last-child{
        margin-bottom: 0;
      }
    }

    .value{
      color: var(--card-border);
      font-weight: bold;
    }
  }

  .level-2{
    padding-left: 20px;
    font-size: 0.9em;

    --card-border: #27a8cd;
    --card-bg: rgb(80 177 181 / 15%);

    .singles-grid{
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .group-container{
    display: grid;
    grid-gap: 10px;

    &.level-0{
      grid-gap: 16px;
    }

    .level-1{
      padding: 16px;
      border: 1px solid #ddd;
      border-radius: 16px;
    }

    .level-2{
      margin-top: 16px;
    }
  }

  .group-head{
    font-weight: bold;
    font-size: 1.15em;
  }

  .group-head--level{
    &-2{
      color: #aaa;
    }
  }

  .long-value{
    word-break: break-word;
  }
}

