.collection-Wearables{
  .collection-nft-lower-col-name{
    color: #a4a4f4 !important;
  }
}

.collection-nft-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: top ease 0.2s;
  position: relative;
  top: 0;
  // height: 400px;
  margin-bottom: 1rem;
  cursor: pointer;

  .collection-nft-media-wrapper {
    width: auto;
    height: fit-content;
    position: relative;

    img {
      // max-width: 100%;
      // max-height: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      aspect-ratio: 1/1 !important;
      max-width: 100%;
      display: block;
      width: 100%;
    }
    video {
      // max-width: 100%;
      // max-height: 100%;
      //https://stackoverflow.com/questions/49907306/strange-gap-at-the-bottom-of-the-html-video-element-when-using-object-fit-cove - display block
      display: block;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      aspect-ratio: 1/1 !important;
      max-width: 100%;
      height: auto;
    }

    &__image-wrapper{
      padding: 10px;
      display: grid;
      place-items: center;
      border: 1px solid #d7d7d7;
      border-bottom-color: transparent;
      border-radius: 12px 12px 0px 0px;

      img{
        // max-height: 150px;
        width: 100%;
      }

      // &.wearables{
      //   height: 150px;
      //   border: 1px solid #d7d7d7;
      //   border-bottom-color: transparent;
      //   border-radius: 12px 12px 0 0;

      // }
    }

    .tag {
      padding: 6px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 16px;
      height: 22px;
      align-items: center;
      display: flex;
    }

    .for-sale {
      background-color: #eff8ff;
      color: #175cd3;
      margin-right: 5px;
    }

    .auction {
      background-color: #f4f3ff;
      color: #5925dc;
    }
  }
  .collection-nft-lower-wrapper {
    border: 1px solid #d7d7d7;
    border-top-color: transparent;
    border-radius: 0 0 12px 12px;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;

    .collection-nft-lower-name {
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      height: 3rem;
      // height: 2rem;
    }
    .collection-nft-lower-col-name {
      color: var(--text-secondary);
      font-weight: 600;
      margin-top: auto;
      font-size: 0.9rem;
      height: 2rem;
      padding-top: 10px;
    }
  }
}

.collection-nft-wrapper:hover {
  top: -5px;
  /* -webkit-filter: brightness(110%); */
  /* transform: scale(1.01); */
  cursor: pointer;
}
// .profile-nft {
//     width: 95%;
//     /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
//     border-radius: 12px;
//     background-color: var(--background-profile);
//     border: none;
//     position: relative;
//     top: 0;
//     transition: top ease 0.2s;

// }

// .profile-nft:hover {
//     top: -5px;
//     /* -webkit-filter: brightness(110%); */
//     /* transform: scale(1.01); */
//     cursor: pointer;
// }

// @media only screen and (max-width: 576px) {
//     .profile-nft {
//         /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
//         border-radius: 12px;
//         margin-left: 0.75rem;
//         margin-right: 0.75rem;

//     }
// }
// #profile-tabs .profiletabs.nav {
//     flex-wrap: inherit;
// }
// .profile-nft img {
//     border-radius: 12px;
//     border-width: 2px;
//     width: 100%;
//     object-fit:cover;
//     background-position:center;
//     aspect-ratio: 1/1 !important;
// }
// .profile-nft video {
//     border-radius: 12px;
//     border-width: 2px;
//     width: 100%;
//     object-fit:cover;
//     background-position:center;
//     aspect-ratio: 1/1 !important;
// }
// .profile-nft-selected {
//     border-color: black;
//     filter: none !important;
// }
// .text-nft {
//     white-space: pre;
//     text-align: center;
//     height: auto;
// }
// .text-nft-wrapper {
//     height: 1912px;
//     display: flex;
// }

// .profile-nft-link {
//     text-decoration: none;
// }

// .tag {
//     padding: 6px;
//     font-size: 12px;
//     font-weight: 500;
//     border-radius: 16px;
//     height: 22px;
//     align-items: center;
//     display: flex;
// }

// .for-sale {
//     background-color: #EFF8FF;
//     color: #175CD3;
//     margin-right: 5px;
// }

// .auction{
//     background-color: #F4F3FF;
//     color: #5925DC;
// }
